import React, { Fragment, useEffect } from 'react';
import { Form, Select } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

const { Option } = Select;

const propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  isPagamentoOnline: PropTypes.bool
};

const defaultProps = {
  isPagamentoOnline: false
};

const LocalEntregaField = ({ form, isPagamentoOnline }) => {
  const autoSelecionarEncontrarEntregadorCasoPagamentoNaEntrega = () => {
    if (!isPagamentoOnline) {
      form.setFieldsValue({
        local_entrega: 'Quero encontrar o entregador.'
      });
    }
  };

  useEffect(autoSelecionarEncontrarEntregadorCasoPagamentoNaEntrega, [
    form,
    isPagamentoOnline
  ]);

  return (
    <Form.Item
      label="Onde o pedido deve ser deixado"
      name="local_entrega"
      rules={[{ message: 'Campo obrigatório', required: true }]}
    >
      <Select disabled={!isPagamentoOnline}>
        {isPagamentoOnline && (
          /* eslint-disable-next-line react/jsx-fragments */
          <Fragment>
            <Option value="Portão da casa / prédio.">
              Portão da casa / prédio.
            </Option>
            <Option value="Portaria do prédio / condomínio.">
              Portaria do prédio / condomínio.
            </Option>
          </Fragment>
        )}
        <Option value="Quero encontrar o entregador.">
          Quero encontrar o entregador.
        </Option>
      </Select>
    </Form.Item>
  );
};

LocalEntregaField.propTypes = propTypes;
LocalEntregaField.defaultProps = defaultProps;

export default LocalEntregaField;
